import React from "react";
import css from "@emotion/css";
import Facebook from "./images/facebook.png";
import Instagram from "./images/instagram.png";
import Twitter from "./images/twitter.png";
import LinkedIn from "./images/linkedin.png";
import AngelList from "./images/angellist.png";
import StackOverflow from "./images/stackoverflow.png";
import Github from "./images/github.png";
import { Link } from "gatsby";
export default function Footer() {
  return (
    <div
      css={css`
        background-color: #6d7e7c;
      `}
      className="section-py"
    >
      <div
        className="container text-white"
        css={css`
          p + h5 {
            margin-top: 1.5rem;
          }
          small {
            opacity: 0.9;
          }
          p {
            line-height: 1.5;
            a,
            a:hover {
              color: #fff;
            }
          }
        `}
      >
        <div className="row d-md-flex justify-content-between">
          <div
            className="col-12
        col-md-3 d-flex "
            css={css`
              a {
                margin-right: 1rem;
              }
              margin-bottom: 1.5rem;

              @media (min-width: 768px) {
                margin-bottom: 0;
              }
            `}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/ratio-network/"
            >
              <img
                alt="LinkedIn"
                src={LinkedIn}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/rationetwork"
            >
              <img
                alt="Facebook"
                src={Facebook}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/rationetwork/"
            >
              <img
                alt="Instagram"
                src={Instagram}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/RatioNetwork"
            >
              <img
                alt="Twitter"
                src={Twitter}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://angel.co/company/ratio-1"
            >
              <img
                alt="AngelList"
                src={AngelList}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackoverflow.com/jobs/companies/ratio"
            >
              <img
                alt="Stack Overflow"
                src={StackOverflow}
                css={css`
                  height: 20px;
                `}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/rationetwork"
            >
              <img
                alt="Github"
                src={Github}
                css={css`
                  height: 20px;
                `}
              />
            </a>
          </div>
          <div
            className="col-12
        col-md-3"
          >
            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Address
            </h5>
            <p>
              <small>
                This Workspace 18 Albert Road Bournemouth BH1 1BZ
              </small>
            </p>
            <p>
              <small>
                Call: +44 1202 232 705
                <br />
                Email:{" "}
                <a href="mailto:always.listening@ratio.co.uk">
                  always.listening@ratio.co.uk
                </a>{" "}
              </small>
            </p>
            <p />
          </div>
          <div className="col-12 col-md-3">
            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Awards
            </h5>
            <p>
              <small
                css={css`
                  line-height: 2.2;
                `}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fasttrack.co.uk/league-tables/tech-track-100/league-table/?leagueyear=2017"
                >
                  Sunday Times Tech Track 100
                </a>{" "}
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.dorsetbusinessawards.co.uk/"
                >
                  Dorset Business Awards
                </a>{" "}
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.insidermedia.com/"
                >
                  Insider International Awards
                </a>{" "}
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.venusawards.co.uk/"
                >
                  Venus Awards
                </a>{" "}
              </small>
            </p>

            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Associations
            </h5>
            <p>
              <small
                css={css`
                  line-height: 2.2;
                `}
              >
                <a href="https://www.innovatefinance.com/">Innovate Finance</a>{" "}
                <br />
                <a href="https://technation.io/">Tech Nation</a>{" "}
              </small>
            </p>
          </div>
          <div className="col-12 col-md-2">
            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Ratio
            </h5>
            <p>
              <small
                css={css`
                  line-height: 2.2;
                `}
              >
                <Link to="/about/">About</Link>
                <br />
                <Link to="/products/">Products</Link>
                <br />
                <Link to="/careers/">Careers</Link>
                <br />
                <Link to="/investors/">Investors</Link>
                <br />
                <Link to="/contact/">Contact</Link>
                <br />
              </small>
            </p>
            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Principles
            </h5>
            <p>
              <small>
                <Link to="/engineering-principles/">
                  The 8 codemandments
                </Link>
                <br />
              </small>
            </p>
            <h5
              css={css`
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
              `}
            >
              Small stuff
            </h5>
            <p>
              <small>
                <Link to="/terms-and-conditions/">Terms & conditions</Link>
                <br />
                <Link to="/privacy/">Privacy</Link>
                <br />
                <Link to="/cookies/">Cookies</Link>
              </small>
            </p>
          </div>
        </div>
        <div className="row d-md-flex justify-content-between">
          <div
            className="col-12 d-flex"
            css={css`
              a {
                margin-right: 1rem;
              }
              margin-bottom: 1.5rem;

              @media (min-width: 768px) {
                margin-bottom: 0;
              }
            `}
          >
            <p>
              <small>
                Ⓒ Copyright Ratio 2025, and beyond. All rights reserved.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
